body {
  font-family: monospace;
  margin: 0;
  font-size: 3rem;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 0 5%;
}

.arrow-box {
  display: grid;
  grid-template-columns: repeat(3, 75px);
  grid-template-rows: repeat(3, 75px);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  &__arrow {
    width: 75px;
    height: 75px;
    &--top {
      @extend .arrow-box__arrow;
      grid-area: 1 / 2 / 2 / 3;
      transform: rotate(90deg);
    }
    &--left {
      @extend .arrow-box__arrow;
      grid-area: 2 / 1 / 3 / 2;
    }
    &--right {
      @extend .arrow-box__arrow;
      grid-area: 2 / 3 / 3 / 4;
      transform: rotate(180deg);
    }
    &--bottom {
      @extend .arrow-box__arrow;
      grid-area: 3 / 2 / 4 / 3;
      transform: rotate(-90deg);
    }
    &--middle {
      @extend .arrow-box__arrow;
      grid-area: 2 / 2 / 3 / 3;
    }
  }
}

.bg {
  grid-area: 2 / 2 / 3 / 3;
  border: 4px solid black;
  -webkit-appearance: none;
  appearance: none;
  width: 5rem;
  height: 5rem;
  padding: 0;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
  }
}
